export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.params.problemUri && to.params.category) {
    const category = categoriesMap[to.params.category];
    const problems = await getDetailedProblems(category, {
      page: 1,
      size: 100,
    });

    const problem = problems.results.find(
      (problem) => problem.repairServiceUriId === to.params.problemUri
    );

    if (!problem) {
      return navigateTo("/not-found");
    }

    const problemState = useState("problem", () => problem);
    problemState.value = problem;
  }
});
